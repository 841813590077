import React from "react";
import { Layout, Menu } from "antd";
import Products from "./components/ProductList";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Product from "./pages/Product";
import ReactGA from "react-ga";


const { Header, Content, Footer } = Layout;
function App(props) {

  ReactGA.initialize("UA-63905846-9");
  ReactGA.set({ dimension1: "Berluti x Threekit" });
  ReactGA.pageview("Berluti Demo | Landing");
  return (
    <div className="App">
      <Layout className="layout">
        <Header id="header"></Header>
        <Content style={{ padding: "1em" }}>
          <Router>
            <Switch>
              <Route
                path="/product/:productId"
                exact
                component={Product}
              ></Route>

              <Route path="/" component={Products}></Route>
              <Route component={Products}></Route>
            </Switch>
          </Router>
        </Content>
        <Footer style={{ textAlign: "center" }}>Berluti ©2020</Footer>
      </Layout>
    </div>
  );
}

export default App;
