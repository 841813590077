import React from "react";
import "./Products.css";
import "antd/dist/antd.css";
import { PageHeader } from "antd";
import { ProductList } from "../config/Configs.js";
import { BrowserRouter as Link } from "react-router-dom";
import ReactGA from "react-ga";


import Form from "../components/Form";

import { apply2DSpin } from "../Helpers/Spin";

import { tdmMap } from "../config/map/tdm.js";

import isEqual from "lodash/isEqual";

let link = "";

let clicked = false;

let loaded = false;

const hideHand = () => {
  // document.getElementById("hand-indicator").classList.remove("hand");
  // document.getElementById("hand-indicator").classList.remove("bounce-2");
  document.getElementById("hand-indicator") == undefined
    ? window.location.reload()
    : (document.getElementById("hand-indicator").style = "display: none");
};

class Product extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      img: "",
      loaded: false,
    };
  }

  componentDidMount() {
    const productId = this.props.match.params.productId;
    const product = ProductList[productId];

    ReactGA.initialize("UA-63905846-9");
    ReactGA.set({ dimension1: "Berluti x Threekit" });
    ReactGA.pageview("Berluti Demo | Chevron");

    if (clicked == false) {
      document
        .getElementById("threekit-container")
        .addEventListener("mousedown", function () {
          // document.getElementById("hand-container").remove();
          hideHand();
        });

      document
        .getElementById("threekit-container")
        .addEventListener("touchstart", function () {
          // document.getElementById("hand-container").remove();
          hideHand();
        });
    }

    !product
      ? console.log("no product")
      : // Put player here
        window
          .threekitPlayer({
            authToken: "38f25736-7d60-4ae7-832a-f0d36cd80802",
            display: "image",
            el: document.getElementById("player"),
            assetId: `${product.threekit}`,
            stageId: `${product.stageId}`,

            showConfigurator: false,
            initialConfiguration: product.config,
            cache: {
              maxAge: 500,
              scope: "6-28-2021"
            }
          })
          .then(async (api) => {
            window.player = api;
            window.configurator = await api.getConfigurator();
            await api.when('loaded')
            await window.configurator.prefetchAttributes(["Rotation"]);
            apply2DSpin({ attrName: "Rotation", direction: -1 })(api)

              this.setState({ loaded: true })
        
          });
  }
  render() {
    const productId = this.props.match.params.productId;
    const product = ProductList[productId];
    return (
      <div id="s">
        {!product ? (
          <div>
            Invalid product URL. Please navigate <a href="/">home</a>
          </div>
        ) : (
          <div>
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)",
              }}
              onBack={() => window.location.assign("/")}
              title={product.name}
            />

            <div id="threekit-container">
              {this.state.loaded ? (
                <div className="stage" id="hand-container">
                  <div id="hand-indicator" className="hand bounce-2">
                    <img
                      style={{ height: "30px", width: "30px" }}
                      src="https://solutions-engineering.s3.amazonaws.com/media/web-assets/hand.png"
                    />
                  </div>
                </div>
              ) : (
                <p></p>
              )}

              <div
                id="player"
                style={{
                  height: "50vh",
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  background: "#ffffff",
                }}
              ></div>
            </div>

            <Form config={product.config} product={product} />
          </div>
        )}
      </div>
    );
  }
}

export default Product;
