const redMap = [
    {
      "0":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/red/Chevron_Simila-Red_00.jpg",
    },
    {
      "1":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/red/Chevron_Simila-Red_01.jpg",
    },
    {
      "2":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/red/Chevron_Simila-Red_02.jpg",
    },
    {
      "3":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/red/Chevron_Simila-Red_03.jpg",
    },
    {
      "4":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/red/Chevron_Simila-Red_04.jpg",
    },
    {
      "5":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/red/Chevron_Simila-Red_05.jpg",
    },
    {
      "6":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/red/Chevron_Simila-Red_06.jpg",
    },
    {
      "7":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/red/Chevron_Simila-Red_07.jpg",
    },
    {
      "8":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/red/Chevron_Simila-Red_08.jpg",
    },
    {
      "9":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/red/Chevron_Simila-Red_09.jpg",
    },
    {
      "10":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/red/Chevron_Simila-Red_10.jpg",
    },
    {
      "11":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/red/Chevron_Simila-Red_11.jpg",
    },
  ];
  
  export { redMap };
  