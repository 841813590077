const tdmMap = [
    {
      "0":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/4/Chevron_00.jpg",
    },
    {
      "1":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/4/Chevron_01.jpg",
    },
    {
      "2":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/4/Chevron_02.jpg",
    },
    {
      "3":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/4/Chevron_03.jpg",
    },
    {
      "4":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/4/Chevron_04.jpg",
    },
    {
      "5":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/4/Chevron_05.jpg",
    },
    {
      "6":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/4/Chevron_06.jpg",
    },
    {
      "7":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/4/Chevron_07.jpg",
    },
    {
      "8":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/4/Chevron_08.jpg",
    },
    {
      "9":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/4/Chevron_09.jpg",
    },
    {
      "10":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/4/Chevron_10.jpg",
    },
    {
      "11":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/4/Chevron_11.jpg",
    },
  ];
  
  export { tdmMap };
  