import React from "react";
import { Avatar, Button, Badge, Tag } from "antd";
import { tdmMap } from "../../config/map/tdm";
import { neroMap } from "../../config/map/nero";
import { brunMap } from "../../config/map/brun";
import { redMap } from "../../config/map/red";


import { SearchOutlined } from "@ant-design/icons";
let newPatina = "";

const getImg = function (patina, config) {
  if (patina == 'TDM Intenso'){
    return `${tdmMap[config][config]}`;
  } else if (patina == 'Nero Grigo'){
    return `${neroMap[config][config]}`;
  } else if (patina == 'Brun'){
    return `${brunMap[config][config]}`;
  }else if (patina == 'Simila Red'){
    return `${redMap[config][config]}`;
  }
};

function Magnify(props) {
  return (
    <Avatar
      size={"large"}
      style={{
        color: "#000000",
        backgroundColor: "#ffffff",
        margin: "5px",
      }}
      onClick={() =>(
        window.open(
          getImg(window.configurator.getConfiguration().Patina, window.configurator.getConfiguration().Rotation),
          "_blank"
        ))
      }
    >
      <SearchOutlined />
    </Avatar>
  );
}

export default Magnify;
