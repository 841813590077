const brunMap = [
    {
      "0":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/brun/Chevron_Brun_00.jpg",
    },
    {
      "1":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/brun/Chevron_Brun_01.jpg",
    },
    {
      "2":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/brun/Chevron_Brun_02.jpg",
    },
    {
      "3":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/brun/Chevron_Brun_03.jpg",
    },
    {
      "4":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/brun/Chevron_Brun_04.jpg",
    },
    {
      "5":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/brun/Chevron_Brun_05.jpg",
    },
    {
      "6":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/brun/Chevron_Brun_06.jpg",
    },
    {
      "7":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/brun/Chevron_Brun_07.jpg",
    },
    {
      "8":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/brun/Chevron_Brun_08.jpg",
    },
    {
      "9":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/brun/Chevron_Brun_09.jpg",
    },
    {
      "10":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/brun/Chevron_Brun_10.jpg",
    },
    {
      "11":
        "https://solutions-engineering.s3.amazonaws.com/berluti-demo/chevron/brun/Chevron_Brun_11.jpg",
    },
  ];
  
  export { brunMap };
  